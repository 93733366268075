// external components
import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";

// internal components
import "./App.css";
import AdvisorInfo from "./components/for_left_sidebar/AdvisorInfo/AdvisorInfo";
import Appointment from "./components/for_left_sidebar/Appointment/Appointment";
import Chat from "./components/for_left_sidebar/Chat/Chat";
import Dashboard from "./components/for_left_sidebar/Dashboard/Dashboard";
import { GetContextApi } from "./ContextApi";

// pages
import Error from "./components/Error/Error";
import CusHeaderPage from "./components/for_left_sidebar/customize/CusHeaderPage/CusHeaderPage";
import CusLeftSidebar from "./components/for_left_sidebar/customize/CusLeftSidebar/CusLeftSidebar";
import CusLoginPage from "./components/for_left_sidebar/customize/CusLoginPage/CusLoginPage";
import Homepage from "./pages/Homepage/Homepage";
import Login from "./pages/Login/Login";

const App = () => {
	// for get selected value from left-sidebar
	const [selected, setSelected] = useState("dashboard");

	// for appointment-details popup toggle
	const [appDisplay, setAppDisplay] = useState(false);

	// for get message id from notification
	const [messageId, setMessageId] = useState("");

	// for create project's colors document on server if not exist start
	useEffect(() => {
		(async () => {
			try {
				const response = await fetch("/customize/create-project-colors-docu");

				const result = await response.json();

				if (response.status === 200) {
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
			}
		})();
	}, []);
	// for create project's colors document on server if not exist end

	// for getting all project's colors start
	const { dispatch } = GetContextApi();

	useEffect(() => {
		(async () => {
			try {
				const response = await fetch("/customize/get-project-colors");

				const result = await response.json();

				if (response.status === 200) {
					dispatch({ type: "getAllColors", payload: result });
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for getting all project's colors end

	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route
						path="/"
						element={
							<Homepage
								selected={selected}
								setSelected={setSelected}
								appDisplay={appDisplay}
								setAppDisplay={setAppDisplay}
								setMessageId={setMessageId}
							/>
						}
					>
						<Route
							path="dashboard"
							element={
								<Dashboard
									setSelected={setSelected}
									setAppDisplay={setAppDisplay}
								/>
							}
						/>
						<Route path="chat" element={<Chat messageId={messageId} />} />
						<Route
							path="create-appointment"
							element={<Appointment setSelected={setSelected} />}
						/>
						<Route path="my-advisor" element={<AdvisorInfo />} />

						<Route path="customize/login-page" element={<CusLoginPage />} />

						<Route path="customize/header" element={<CusHeaderPage />} />

						<Route path="customize/left-sidebar" element={<CusLeftSidebar />} />
					</Route>
					<Route path="log-in" element={<Login />} />

					<Route path="login" element={<Navigate to="/log-in" />} />

					<Route path="*" element={<Error />} />
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default App;
